exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-april-2019-building-portfolio-with-gatsby-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/april-2019/building-portfolio-with-gatsby.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-april-2019-building-portfolio-with-gatsby-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-git-hook-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/git-hook.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-git-hook-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-how-i-work-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/how-i-work.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-how-i-work-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-july-2019-my-netis-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/july-2019/my-netis.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-july-2019-my-netis-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-may-2019-a-star-search-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/may-2019/a-star-search.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-may-2019-a-star-search-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-abp-blood-bank-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/ABPBloodBank.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-abp-blood-bank-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-accounts-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/accounts.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-accounts-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-bani-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/Bani.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-bani-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-bd-data-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/bd-data.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-bd-data-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-blog-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/blog.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-blog-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-cfviz-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/cfviz.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-cfviz-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-digital-tuta-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/digital_tuta.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-digital-tuta-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-dingulo-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/dingulo.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-dingulo-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-dot-connect-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/dot-connect.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-dot-connect-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-fire-adda-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/fire-adda.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-fire-adda-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-lightoj-solutions-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/lightoj-solutions.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-lightoj-solutions-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-make-them-fall-the-game-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/MakeThemFallTheGame.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-make-them-fall-the-game-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-mobile-mela-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/mobile-mela.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-mobile-mela-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-my-netis-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/my-netis.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-my-netis-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-name-the-code-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/name-the-code.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-name-the-code-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-nu-gpacalculator-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/nu-gpacalculator.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-nu-gpacalculator-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-package-cloud-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/package-cloud.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-package-cloud-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-psbook-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/psbook.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-psbook-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-projects-psbookprogress-mdx": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/projects/psbookprogress.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-projects-psbookprogress-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-scraping-light-oj-part-i-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/scraping-light-oj-part-i.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-scraping-light-oj-part-i-md" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-scraping-light-oj-part-ii-md": () => import("./../../../src/templates/postTemplate.js?__contentFilePath=/opt/build/repo/src/posts/scraping-light-oj-part-ii.md" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-scraping-light-oj-part-ii-md" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

